import React from 'react';
import PocketBase from "pocketbase";
const url = "https://test-tod.pockethost.io/";
const client = new PocketBase(url);
client.autoCancellation(false);

const pbcreds = () => {
    
  return (
    <div></div>
  )
}

export default pbcreds