import React from 'react';
import FeaturedCategories from './FeaturedCategory';
import HeroSection from './HeroSection';
import MyCarousel from './MyCarousel';
import PopularProducts from './PopularProducts';
import ServiceAvailability from './ServiceAvailability';
import SpecialOffers from './SpecialOffers';
import Testimonials from './Tertimonials';

const Home = () => {
  return (
    <div>
        <HeroSection />
      <ServiceAvailability />
      <FeaturedCategories />
      <PopularProducts />
      <Testimonials />
      <MyCarousel />
      <SpecialOffers />
    </div>
  )
}

export default Home