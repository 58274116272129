import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const HeroSection = () => {
  return (
    <div className="hero-section">
      <Container className="my-4">
        <Row className="align-items-center">
          <Col lg={6} className="mb-4 mb-lg-0">
            <h1>Welcome to Party & Decorations!</h1>
            <p className="lead">
              Make your events memorable with our wide range of party supplies and decoration services.
            </p>
            <Button variant="primary" size="lg">Explore Packages</Button>
          </Col>
          <Col lg={6}>
            <img
              className="img-fluid"
              src="https://housing.com/news/wp-content/uploads/2023/03/Colours-of-Holi-What-is-the-significance-of-different-colours-f.jpg"
              alt="Hero Image"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
