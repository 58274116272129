import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import PocketBase from "pocketbase";
import "./FeaturedCategory.css";
import { Link } from "react-router-dom";



const url = "https://test-tod.pockethost.io/";
const client = new PocketBase(url);
client.autoCancellation(false);

const FeaturedCategories = () => {

  const [category, setCategory] = useState([]);

  useEffect(() => {
    client
      .collection("category")
      .getFullList()
      .then((res) => {
        setCategory(res);
      });
  }, []);

  

  return (
    <div className="featured-categories">
      <Container>
        {/* <h2 className="text-center mb-4">Featured Categories</h2> */}
        <div className="featured-category">
  <h2 className="featured-heading">Featured Categories</h2>
  <a href="/category" className="view-all">View All</a>
</div>

        <Row>
        {category.map((cat) => (

          <Col xs={6} sm={4} lg={4} className="mb-4" key={cat.id}>
            <Link to={`/category/${cat.id}`}>
            <Card >
              <Card.Img variant="top" src={
                        "https://test-tod.pockethost.io/api/files/category/" +
                        cat.id +
                        "/" +
                        cat.category_image
                      } />
              {/* <Card.Body>
                <Card.Title>{cat.category}</Card.Title>
                <Card.Text>
                  Explore our collection of decorations for birthdays, weddings, and more.
                </Card.Text>
              </Card.Body> */}
            </Card>
            </Link>

          </Col>
              ))}

          
        </Row>
      </Container>
    </div>
  );
};

export default FeaturedCategories;
