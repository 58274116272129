import React from 'react'
import PocketBase from "pocketbase";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';


const url = "https://test-tod.pockethost.io/";
const client = new PocketBase(url);
client.autoCancellation(false);

const FilteredCategory = () => {
const [products, setProducts] = useState([]);
  const [catName, setCatName] = useState([]);
  // const [catNames, setCatNames] = useState([]);
  const location = useLocation();
  const category = location.pathname.split("/")[2];
  useEffect(() => {
    client
      .collection("products")
      .getFullList()
      .then((res) => {
        if (category) {
          const filteredCategory = res.filter(
            (ele) => ele.category == category
          );
          setProducts(filteredCategory);
        } else {
          setProducts(res);
        }
      });
    client
      .collection("category")
      .getFullList()
      .then((res) => {
        if (category) {
          const filteredCat = res.filter((ele) => ele.id == category);
          setCatName(filteredCat[0]);
          //   setCatNames(catName.category);
        } else {
          setCatName(res);
        }
      });
  }, []);
  return (
    <div className="product-page" style={{ marginTop: '50px', marginBottom: '50px' }}>
      <Container>
        <h2 className="text-center mb-4">{catName.category}</h2>
        <Row xs={1} md={2} lg={3} className="g-4">
        {products.map((cat) => (

            <Col key={cat.id}>
              <Card style={{ marginTop: '5px', marginBottom: '5px' }}>
              <Link to={`/product/${cat.id}`}>

                <Card.Img variant="top" src={
                        "https://test-tod.pockethost.io/api/files/products/" +
                        cat.id +
                        "/" +
                        cat.image
                      } alt={cat.name} />
              </Link>

                <Card.Body>
              <Link to={`/product/${cat.id}`}>

                  <Card.Title style={{color: 'black'}}>{cat.name}</Card.Title>
              </Link>

                  {/* <Card.Text>{product.description}</Card.Text> */}
                  <Card.Text style={{color: 'red'}}>Price: ₹{cat.original_price}</Card.Text>
                  {/* <Button variant="primary">View</Button> */}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default FilteredCategory