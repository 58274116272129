import React from 'react';
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer" style={{ marginTop: '50px' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h4>Contact Us</h4>
            <p>Email: info@example.com</p>
            <p>Phone: +1234567890</p>
            <p>Address: 123 Main Street, City, Country</p>
          </div>
          <div className="col-md-6">
            <h4>Follow Us</h4>
            <p>Stay connected with us on social media:</p>
            <ul className="social-icons">
              <li><a href="https://facebook.com/example" target="_blank" rel="noopener noreferrer">Facebook</a></li>
              <li><a href="https://twitter.com/example" target="_blank" rel="noopener noreferrer">Twitter</a></li>
              <li><a href="https://instagram.com/example" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center py-3">
        <p>&copy; 2024 Your Company. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
