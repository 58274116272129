import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PocketBase from "pocketbase";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const url = "https://test-tod.pockethost.io/";
const client = new PocketBase(url);
client.autoCancellation(false);

// Sample product data
// const products = [
//   { id: 1, name: 'Product 1', description: 'Description of Product 1', price: 10, imageUrl: 'https://via.placeholder.com/300x200' },
//   { id: 2, name: 'Product 2', description: 'Description of Product 2', price: 20, imageUrl: 'https://via.placeholder.com/300x200' },
//   { id: 3, name: 'Product 3', description: 'Description of Product 3', price: 15, imageUrl: 'https://via.placeholder.com/300x200' },
//   { id: 4, name: 'Product 4', description: 'Description of Product 4', price: 12, imageUrl: 'https://via.placeholder.com/300x200' },
//   { id: 5, name: 'Product 5', description: 'Description of Product 5', price: 18, imageUrl: 'https://via.placeholder.com/300x200' },
//   { id: 6, name: 'Product 6', description: 'Description of Product 6', price: 25, imageUrl: 'https://via.placeholder.com/300x200' },
//   { id: 7, name: 'Product 7', description: 'Description of Product 7', price: 30, imageUrl: 'https://via.placeholder.com/300x200' },
//   { id: 8, name: 'Product 8', description: 'Description of Product 8', price: 22, imageUrl: 'https://via.placeholder.com/300x200' },
//   { id: 9, name: 'Product 9', description: 'Description of Product 9', price: 16, imageUrl: 'https://via.placeholder.com/300x200' },
//   { id: 10, name: 'Product 10', description: 'Description of Product 10', price: 14, imageUrl: 'https://via.placeholder.com/300x200' },
// ];

const ProductPage = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    client
      .collection("products")
      .getFullList()
      .then((res) => {
        setProducts(res);
      });
  }, []);
  return (
    <div className="product-page" style={{ marginTop: '50px', marginBottom: '50px' }}>
      <Container>
        <h2 className="text-center mb-4">Our Products</h2>
        <Row xs={1} md={2} lg={3} className="g-4">
        {products.map((cat) => (
            <Col key={cat.id}>
              <Card style={{ marginTop: '5px', marginBottom: '5px' }}>
              <Link to={`/product/${cat.id}`}>

                <Card.Img variant="top" src={
                        "https://test-tod.pockethost.io/api/files/products/" +
                        cat.id +
                        "/" +
                        cat.image
                      }
                      alt="" />
              </Link>

                <Card.Body>
              <Link to={`/product/${cat.id}`}>

                  <Card.Title style={{color: 'black'}}>{cat.name}</Card.Title>
              </Link>

                  {/* <Card.Text>{product.description}</Card.Text> */}
                  <Card.Text style={{color: 'red'}}>Price: ₹{cat.original_price}</Card.Text>
                  {/* <Button variant="primary">View</Button> */}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ProductPage;
