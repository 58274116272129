import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import PocketBase from "pocketbase";
import { Link } from "react-router-dom";


const url = "https://test-tod.pockethost.io/";
const client = new PocketBase(url);
client.autoCancellation(false);

// Sample product data
const products = [
  { id: 1, name: 'Product 1', description: 'Description of Product 1', price: 10, imageUrl: 'https://via.placeholder.com/300x200' },
  { id: 2, name: 'Product 2', description: 'Description of Product 2', price: 20, imageUrl: 'https://via.placeholder.com/300x200' },
  { id: 3, name: 'Product 3', description: 'Description of Product 3', price: 15, imageUrl: 'https://via.placeholder.com/300x200' },
  { id: 4, name: 'Product 4', description: 'Description of Product 4', price: 12, imageUrl: 'https://via.placeholder.com/300x200' },
  { id: 5, name: 'Product 5', description: 'Description of Product 5', price: 18, imageUrl: 'https://via.placeholder.com/300x200' },
  { id: 6, name: 'Product 6', description: 'Description of Product 6', price: 22, imageUrl: 'https://via.placeholder.com/300x200' },
  // Add more products as needed
];

const ProductDetailPage = () => {
  const [product, setProduct] = useState([]);
  const location = useLocation();
  const productLocation = location.pathname.split("/")[2];

  useEffect(() => {
    client
      .collection("products")
      .getFullList()
      .then((res) => {
        if (productLocation) {
          const filteredProduct = res.filter(
            (ele) => ele.id === productLocation
          );
          setProduct(filteredProduct[0]);
        } else {
          console.log(res);
        }
      });
  }, []);
  
  const [currentProducts, setCurrentProducts] = useState(products.slice(0, 3));

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = currentProducts[0].id % (products.length - 2) + 1;
      setCurrentProducts(products.slice(nextIndex - 1, nextIndex + 2));
    }, 3000);

    return () => clearInterval(interval);
  }, [currentProducts]);

  return (
    <div className="product-detail-page" style={{ marginTop: '50px', marginBottom: '50px' }}>
      <Container>
        <Row>
          <Col md={6}>
            <Card>
              <Card.Img variant="top" src={
              "https://test-tod.pockethost.io/api/files/products/" +
              product.id +
              "/" +
              product.image
            }
            alt="" />
            </Card>
          </Col>
          <Col md={6}>
            <h2>{product.name}</h2>
            <p dangerouslySetInnerHTML={{ __html: product.desc }}></p>
            <p>₹{product.original_price}</p>
            <a href="tel:+917533030500">
            <Button variant="primary">Call Us</Button>
            </a>
            <Link to={`https://wa.me/917533030500?text=Hello There, I would like to know more about ${product.name}`}>

            <Button variant="primary" style={{backgroundColor: 'green', marginLeft: '10px'}}>WhatsApp
            
            </Button>
            </Link>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <h3>Similar Products</h3>
            <Carousel interval={null} indicators={false}>
              <Carousel.Item>
                <Row>
                  {currentProducts.map(product => (
                    <Col key={product.id}>
                      <Card>
                        <Card.Img variant="top" src={product.imageUrl} alt={product.name} />
                        <Card.Body>
                          <Card.Title>{product.name}</Card.Title>
                          <Card.Text >{product.description}</Card.Text>
                          <Card.Text>Price: ${product.price}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductDetailPage;
