import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PocketBase from "pocketbase";
import { useEffect, useState } from "react";

const url = "https://test-tod.pockethost.io/";
const client = new PocketBase(url);
client.autoCancellation(false);

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    client
      .collection("testimonials")
      .getFullList()
      .then((res) => {
        setTestimonials(res);
      });
  }, []);
  return (
    <div className="testimonials-section">
      <Container>
        <h2 className="text-center mb-4">Testimonials</h2>
        <Row>
        {testimonials.map((test) => (

          <Col md={4} className="mb-4" key={test.id}>
            <Card >
              <Card.Body>
                <Card.Text>
                  {test.comment}
                </Card.Text>
                <p className="font-weight-bold">- {test.name}</p>
              </Card.Body>
            </Card>
          </Col>
              ))}

        
        </Row>
      </Container>
    </div>
  );
};

export default Testimonials;
