import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const AboutUsSection = () => {
  return (
    <div className="about-us-section" style={{ marginTop: '50px', marginBottom: '50px' }}>
      <Container>
        <Row>
          <Col md={6}>
            <h2>About Us</h2>
            <p>
              Welcome to Party & Decorations! We are dedicated to making your events truly memorable with our wide range of party supplies and decoration services. Whether you're planning a birthday party, wedding, baby shower, or any other special occasion, we have everything you need to create the perfect atmosphere.
            </p>
            <p>
              Our team is passionate about helping you bring your vision to life. From elegant tableware and stylish decorations to fun party favors and accessories, we offer high-quality products that will impress your guests and make your event unforgettable.
            </p>
            <p>
              With years of experience in the party and event industry, we understand the importance of attention to detail and exceptional customer service. We're here to help you every step of the way, from selecting the perfect decorations to ensuring timely delivery and setup.
            </p>
          </Col>
          <Col md={6}>
            <img
              className="img-fluid"
              src="https://via.placeholder.com/600x400"
              alt="About Us"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUsSection;
