import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ContactInfoSection = () => {
  return (
    <div className="contact-info-section" style={{ marginTop: '50px', marginBottom: '50px' }}>
      <Container>
        <Row>
          <Col md={6}>
            <h2>Contact Us</h2>
            <p>If you have any questions or inquiries, feel free to reach out to us:</p>
            <ul>
              <li>Email: info@example.com</li>
              <li>Phone: +1234567890</li>
              <li>Address: 123 Main Street, City, Country</li>
            </ul>
          </Col>
          <Col md={6}>
            <img
              className="img-fluid"
              src="https://via.placeholder.com/600x400"
              alt="Contact Us"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactInfoSection;
