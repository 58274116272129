import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";

import PocketBase from "pocketbase";
import { useEffect, useState } from "react";

const url = "https://test-tod.pockethost.io/";
const client = new PocketBase(url);
client.autoCancellation(false);



const CategoryPage = () => {
  const [category, setCategory] = useState([]);

  useEffect(() => {
    client
      .collection("category")
      .getFullList()
      .then((res) => {
        setCategory(res);
      });
  }, []);

  return (
    <div className="category-page" style={{ marginTop: '50px', marginBottom: '50px' }}>
      <Container>
        <h2 className="text-center mb-4">All Categories</h2>
        <Row xs={1} md={3} className="g-4">
        {category.map((cat) => (

            <Col key={cat.id} xs={6} style={{ marginTop: '5px', marginBottom: '5px' }}>
              <Card style={{ cursor: 'pointer' }}>
              <Link to={`/category/${cat.id}`}>

                <Card.Img src={
                      "https://test-tod.pockethost.io/api/files/category/" +
                      cat.id +
                      "/" +
                      cat.category_image
                    } alt="" />
              </Link>

                {/* <Card.Body>
              <Link to={`/category/${cat.id}`}>

                  <Card.Title style={{color: 'black'}}>{cat.category}</Card.Title>
              </Link>

                </Card.Body> */}
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CategoryPage;
