import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PocketBase from "pocketbase";
import { useEffect, useState } from "react";
import "./PopularProducts.css";
import { Link } from "react-router-dom";


const url = "https://test-tod.pockethost.io/";
const client = new PocketBase(url);
client.autoCancellation(false);

const PopularProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    client
      .collection("products")
      .getFullList()
      .then((res) => {
        setProducts(res);
      });
  }, []);

  const popularProducts = products.slice(0, 6);

  return (
    <div className="popular-products">
      <Container>
        {/* <h2 className="text-center mb-4">Popular Products</h2> */}
        <div className="popular-prod">
  <h2 className="featured-heading">Popular Products</h2>
  <a href="/product" className="view-all">View All</a>
</div>
        <Row>
        {popularProducts.map((cat) => (

          <Col xs={6} sm={4} lg={4} className="mb-4" key={cat.id}>

            <Card>
            <Link to={`/product/${cat.id}`}>

              <Card.Img variant="top" src={
                        "https://test-tod.pockethost.io/api/files/products/" +
                        cat.id +
                        "/" +
                        cat.image
                      } />
            </Link>

              <Card.Body>
            <Link to={`/product/${cat.id}`}>

                <Card.Title style={{color: 'black'}}>{cat.name}</Card.Title>
            </Link>

                <Card.Text style={{color: 'red'}}>
                ₹{cat.original_price}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
              ))}

          
          {/* Add more products as needed */}
        </Row>
      </Container>
    </div>
  );
};

export default PopularProducts;
