

import CategoryPage from './Category/CategoryPage';
import ContactInfoSection from './Contact/ContactInfoSection';
import Footer from './Footer';
import OurTeam from './OurTeam';
import PartyNavbar from './PartyNavbar';
import ProductDetailPage from './Products/ProductDetailPage';
import ProductPage from './Products/ProductPage';
import AboutUsSection from './About/AboutUsSection';
import Home from './Home/Home';
import FilteredCategory from './Products/FilteredCategory';

import pbcreds from './pbcreds';
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import WhatsApp from './WhatsApp/WhatsApp';

function App() {
  return (
    <div className="App">
      <PartyNavbar />
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/category" element={<CategoryPage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/about" element={<AboutUsSection />} />
        <Route path="/contact" element={<ContactInfoSection />} />
        <Route path="/category/:id" element={<FilteredCategory />} />
        <Route path="/team" element={<OurTeam />} />
        <Route path="/product/:id" element={<ProductDetailPage />} />



        </Routes>
      </BrowserRouter>
      <WhatsApp />
      <Footer />
{/*       
      <AboutUsSection />
      <ContactInfoSection />
      <Footer />

      <CategoryPage />

      <ProductPage />

      <ProductDetailPage />

      <OurTeam /> */}
    </div>
  );
}

export default App;
