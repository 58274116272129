import React from 'react';
import "./Party.css";
import PocketBase from "pocketbase";
import { useEffect, useState } from "react";

const url = "https://test-tod.pockethost.io/";
const client = new PocketBase(url);
client.autoCancellation(false);

function PartyNavbar() {

  const [category, setCategory] = useState([]);

  useEffect(() => {
    client
      .collection("category")
      .getFullList()
      .then((res) => {
        setCategory(res);
      });
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-custom" style={{ fontSize: '20px' }}>
      <a className="navbar-brand" href="/"><i className="fas fa-glass-cheers mr-2"></i>DARKSUTRA</a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active">
            <a className="nav-link" href="/"><i className="fas fa-home mr-1"></i>Home <span className="sr-only">(current)</span></a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" href="/category"><i className="fas fa-birthday-cake mr-1"></i>All Category</a>
          </li> */}
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/category" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-birthday-cake mr-1"></i>
              Category
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            {category.map((cat) => (

              <a key={cat.id} className="dropdown-item" href={`/category/${cat.id}`}>{cat.category}</a>
              ))}

              
            </div>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/product"><i className="fas fa-gift mr-1"></i>Products</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/team"><i className="fas fa-users mr-1"></i>Team</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/about">About</a>
          </li>
        </ul>
        <form className="form-inline my-2 my-lg-0">
          <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
          <button className="btn btn-outline-danger my-2 my-sm-0" type="submit"><i className="fas fa-search mr-1"></i>Search</button>
        </form>
      </div>
    </nav>
  );
}

export default PartyNavbar;
