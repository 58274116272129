import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const OurTeam = () => {
  const teamMembers = [
    {
      id: 1,
      name: 'John Doe',
      position: 'CEO',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et tortor eget lorem consectetur luctus at ut arcu.',
      imageUrl: 'https://via.placeholder.com/150',
    },
    {
      id: 2,
      name: 'Jane Smith',
      position: 'Marketing Manager',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et tortor eget lorem consectetur luctus at ut arcu.',
      imageUrl: 'https://via.placeholder.com/150',
    },
    // Add more team members as needed
  ];

  return (
    <div className="our-team-section" style={{ marginTop: '50px', marginBottom: '50px' }}>
      <Container>
        <h2 className="text-center mb-4">Our Team</h2>
        <Row>
          {teamMembers.map(member => (
            <Col key={member.id} md={4}>
              <Card className="mb-4">
                <Card.Img variant="top" src={member.imageUrl} alt={member.name} />
                <Card.Body>
                  <Card.Title>{member.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">{member.position}</Card.Subtitle>
                  <Card.Text>{member.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default OurTeam;
