import React from 'react';
import { Container } from 'react-bootstrap';

const ServiceAvailability = () => {
  return (
    <div className="service-availability" style={{ marginTop: '50px', marginBottom: '50px' }}>
      <Container>
        <h2 className="text-center mb-4">Service Availability</h2>
        <p className="text-center">Our services are currently available in Delhi and Delhi NCR.</p>
      </Container>
    </div>
  );
};

export default ServiceAvailability;
