import React from 'react';
import { Carousel } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import PocketBase from "pocketbase";


const url = "https://test-tod.pockethost.io/";
const client = new PocketBase(url);
client.autoCancellation(false);

const MyCarousel = () => {

  const [carousel, setCaraousel] = useState([]);

  useEffect(() => {
    
    client
      .collection("caraousel")
      .getFullList()
      .then((res) => {
        setCaraousel(res);
      });
  }, []);
  return (
    <Carousel>
        {carousel.map((car) => (

      <Carousel.Item key={car.id}>
        <img
          className="d-block w-100"
          src={
            "https://test-tod.pockethost.io/api/files/caraousel/" +
            car.id +
            "/" +
            car.image
          }
          alt="First slide"
        />
        
      </Carousel.Item>
        ))}

      
    </Carousel>
  );
};

export default MyCarousel;
