import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const SpecialOffers = () => {
  return (
    <div className="special-offers-section" style={{ marginTop: '50px', marginBottom: '50px' }}>
      <Container>
        <h2 className="text-center mb-4">Special Offers</h2>
        <Row>
          <Col md={4} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>50% Off</Card.Title>
                <Card.Text>
                  Get 50% off on all birthday party decorations. Limited time offer!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>Free Shipping</Card.Title>
                <Card.Text>
                  Enjoy free shipping on all orders over $50. No promo code required.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>Buy One, Get One Free</Card.Title>
                <Card.Text>
                  Buy one pack of party balloons and get another pack free. Don't miss out!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SpecialOffers;
